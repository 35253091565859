@import '../../styles/core.css';

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000010;
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: 28px;
  right: 28px;
  z-index: 1020;
  color: var(--default-grey-10);
  background-color: transparent;

  & :global {
    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.carousel {
  display: flex;
  width: 100%;
  height: calc(100vh - env(safe-area-inset-bottom) - 64px);

  & :global {
    .swiper-wrapper {
      box-sizing: border-box;
    }
  }
}

.panel {
  position: relative;
  z-index: 1010;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & .carousel {
    & :global {
      .carousel-control--prev {
        left: 0;
      }

      .carousel-control--next {
        right: 0;
      }
    }
  }
}

.fullHeight {
  height: calc(100vh - env(safe-area-inset-bottom));
}

.carousel.fullHeight {
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--default-grey-70-a-50);
  backdrop-filter: blur(10px);
}

.thumbs {
  display: flex;
  width: 100%;
  height: 64px;

  & :global {
    .swiper-wrapper {
      gap: 24px;
      justify-content: flex-start;
      padding: 0 16px;
    }

    .swiper-slide {
      box-sizing: border-box;
      opacity: 0.5;
    }

    .swiper-slide-thumb-active {
      position: relative;
      opacity: 1;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: inline-block;
        width: 100%;
        border-top: 1px solid var(--priority-primary-100);
      }
    }

    .swiper-slide,
    picture {
      display: flex;
      width: 48px;
      height: 48px;
    }

    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}

@media (--screen-xs) {
  .panel {
    & .carousel {
      & :global {
        .carousel-control--prev {
          left: 16px;
        }

        .carousel-control--next {
          right: 16px;
        }
      }
    }
  }

  .thumbs {
    & :global {
      .swiper-wrapper {
        padding: 0 32px;
      }
    }
  }
}

@media (--screen-lg) {
  .carousel {
    height: calc(100vh - env(safe-area-inset-bottom) - 84px);
  }

  .close {
    top: 32px;
    right: 32px;

    & :global {
      svg {
        width: 36px;
        height: 36px;
      }
    }
  }

  .panel {
    & .carousel {
      & :global {
        .carousel-control--prev {
          left: 20px;
        }

        .carousel-control--next {
          right: 20px;
        }
      }
    }
  }

  .thumbs {
    height: 84px;

    & :global {
      .swiper-wrapper {
        gap: 32px;
        padding: 0 48px;
      }

      .swiper-slide,
      picture {
        width: 64px;
        height: 64px;
      }
    }
  }
}

@media (--screen-2xl) {
  .thumbs {
    & :global {
      .swiper-wrapper {
        justify-content: center;
      }
    }
  }
}

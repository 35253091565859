@import '../../shared/styles/core.css';

.root {
  & :global {
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .wrapper {
    width: 100%;
    height: 100%;

    & .content {
      width: 100%;
      height: 100%;
    }
  }
}

.picture,
.video {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 24px);
  aspect-ratio: 16/9;

  & :global {
    .picture__img {
      display: flex;
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}

.content,
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbIcon {
  display: flex;
  width: 36px;
  height: 36px;
  color: var(--priority-primary-80);
}

@media (--screen-xs) {
  .picture,
  .video {
    max-width: calc(100% - 60px);
  }
}

@media (--screen-lg) {
  .picture,
  .video {
    max-width: calc(100% - 210px);
    max-height: calc(100% - 64px);
  }

  .thumbIcon {
    width: 54px;
    height: 54px;
  }
}
